const COMMON_CONFIGS = {
  recaptcha_key: process.env.REACT_APP_RECAPTCHA_SECRET_KEY
};

const config = {
  production: {
    ...COMMON_CONFIGS,
    env: process.env.NODE_ENV,
    base_url: process.env.REACT_APP_GATEWAY_URL,
    graphql_url: process.env.REACT_APP_GRAPHQL_URL
  },
  development: {
    ...COMMON_CONFIGS,
    env: process.env.NODE_ENV,
    // LOCAL
    base_url: 'http://192.168.88.46:5003',
    graphql_url: 'https://8697-5-77-204-192.ngrok-free.app/graphql'
    // PROD
    // base_url: 'https://api.medicusunion.com',
    // graphql_url: 'https://gql.medicusunion.com'
    // STAGING
    // base_url: 'https://stg-gateway.medicusunion.me',
    // graphql_url: 'https://stg-gql.medicusunion.me'
  },
  test: {
    ...COMMON_CONFIGS,
    env: process.env.NODE_ENV,
    base_url: process.env.REACT_APP_GATEWAY_URL,
    graphql_url: process.env.REACT_APP_GRAPHQL_URL
  }
};

export default config[process.env.NODE_ENV];
