import { gql } from '@apollo/client';

export const GET_PARTNERS = gql`
  query GetPartners($query: PartnerQueryInput!) {
    getPartners(query: $query) {
      data {
        total
        results {
          id
          name
          logo
          code
          image
          title
          email
          status
          reason
          qr_code
          website
          qr_color
          created_at
        }
      }
    }
  }
`;

export const GET_PARTNER_BY_ID = gql`
  query GetPartner($id: ID!) {
    getPartner(id: $id) {
      data {
        id
        name
        logo
        code
        image
        title
        email
        status
        reason
        qr_code
        website
        qr_color
      }
    }
  }
`;

export const GET_ANALYTICS_BY_PARTNER_ID = gql`
  query GetDataByPartnerId($id: ID!) {
    getDataByPartnerId(id: $id) {
      data {
        chart_data
      }
    }
  }
`;
