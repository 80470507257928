import styled from 'styled-components';

export const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: 190px;

  .remove_logo {
    position: absolute;
    width: 100%;
    height: 23px;
    background: white;
    bottom: 0;
  }

  > div {
    height: 100%;
  }
`;
