import { FC, useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Card, Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { ChartContainer } from './styled';

const { Paragraph, Text } = Typography;

interface IProps {
  data: {
    country: string;
    value: number;
  }[];
}

const CountriesChart: FC<IProps> = ({ data }) => {
  const { t } = useTranslation();
  useEffect(() => {
    const chartDiv = document.getElementById('countriesChart');

    if (!chartDiv) {
      return;
    }

    const root = am5.Root.new('countriesChart');

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        paddingLeft: 0,
        paddingRight: 1
      })
    );

    const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
    cursor.lineY.set('visible', false);

    const xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      minorGridEnabled: false
    });

    xRenderer.labels.template.setAll({
      visible: false
    });

    xRenderer.grid.template.setAll({
      strokeOpacity: 0
    });

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: 'country',
        renderer: xRenderer,
        tooltip: undefined
      })
    );

    const yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0
    });

    yRenderer.labels.template.setAll({
      visible: false
    });

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: yRenderer
      })
    );

    yAxis.get('renderer')?.grid.template.setAll({
      strokeOpacity: 0
    });

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Series 1',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        sequencedInterpolation: true,
        categoryXField: 'country',
        tooltip: am5.Tooltip.new(root, {
          labelText: '{categoryX}: {valueY}'
        })
      })
    );

    series.columns.template.setAll({
      cornerRadiusTL: 0,
      cornerRadiusTR: 0,
      strokeOpacity: 0,
      fill: am5.color(0x2552f3),
      stroke: am5.color(0x2552f3),
      tooltipY: 0
    });

    series.bullets.clear();

    xAxis.data.setAll(data);
    series.data.setAll(data);

    series.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data]);

  return (
    <Card style={{ height: '100%' }} bodyStyle={{ height: '100%' }}>
      <Paragraph style={{ fontSize: 20, fontWeight: 500, margin: 0 }}>
        {t('partners.countries')}
      </Paragraph>
      <Text style={{ fontSize: 32 }}>{data.length}</Text>
      {!!data.length && (
        <ChartContainer>
          <div id="countriesChart" className="chartdiv" />
          <div className="remove_logo" />
        </ChartContainer>
      )}
      <div style={{ width: '50%' }}>
        <Flex justify="space-between">
          <Paragraph style={{ marginBottom: 6, fontWeight: 500 }}>
            {t('partners.countries')}
          </Paragraph>
          <Paragraph style={{ marginBottom: 6, fontWeight: 500 }}>
            {t('partners.visitors')}
          </Paragraph>
        </Flex>
        {data.length ? (
          data?.map(item => {
            return (
              <Flex key={item.country + item.value} justify="space-between">
                <Text>{item.country}</Text>
                <Text>{item.value}</Text>
              </Flex>
            );
          })
        ) : (
          <Flex justify="space-between">
            <Text>0</Text>
            <Text>0</Text>
          </Flex>
        )}
      </div>
    </Card>
  );
};

export default CountriesChart;
