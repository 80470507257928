import {
  Form,
  Image,
  Input,
  Row,
  Collapse,
  Upload,
  Typography,
  Spin,
  Button,
  App
} from 'antd';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_BLOG, UPDATE_BLOG } from 'gql/blog/mutations';
import { CreateBlog } from 'gql/blog/__generated__/CreateBlog';
import { GET_BLOG_NEWS_BY_ID } from 'gql/blog/queries';
import { LANGUAGES } from 'constants/languages';
import { Blog } from 'gql/blog/__generated__/Blog';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useFileUpload } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';

import { showErrorMessage } from 'utils/showErrorMessage';
import { CKEditor } from 'components/shared';
import { Loading, PageWrapper } from 'components/ui';
import { parseJson } from 'utils/json';
import { getInputDir } from 'utils/helpers';

import 'react-quill/dist/quill.snow.css';

const itemValues = {
  title: '',
  body: '',
  short_desc: '',
  meta_title: '',
  meta_description: '',
  meta_keywords: ''
};

type ValuesType = typeof itemValues;
type BodyType = Record<string, ValuesType>;

const { Panel } = Collapse;
const { Text } = Typography;

const BlogNewsDetails: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const params = useParams();
  const { modal } = App.useApp();
  const blogId = params?.id;
  //AWS
  const {
    fileUrl: file,
    loading: isUploading,
    result: fileValue,
    setResult,
    upload
  } = useFileUpload({
    handleResult: true,
    loading: true
  });

  const [isFetching, setFetching] = useState(!!blogId);
  const [errorKeys, setErrorKeys] = useState<string[]>([]);

  const showSuccess = (text: string) => {
    modal.success({
      title: 'Success',
      content: text,
      onOk() {
        navigate('/blog-news');
      },
      onCancel() {
        navigate('/blog-news');
      }
    });
  };

  const [createBlog, { loading: isCreating }] = useMutation<CreateBlog>(
    CREATE_BLOG,
    {
      onCompleted() {
        showSuccess(
          'Your blog has been successfully created. Please note, it may take a little time to become available in the app.'
        );
      },
      onError: err => showErrorMessage(err)
    }
  );

  useQuery<Blog>(GET_BLOG_NEWS_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      id: blogId
    },
    skip: !blogId,
    onCompleted(data) {
      if (data.blog?.data) {
        const { image, slug, translates } = data.blog.data;

        const values = translates.reduce((acc: BodyType, val) => {
          const keywords = parseJson(val.keywords);

          return {
            ...acc,
            [val.lang]: {
              title: val.title,
              body: val.body || '',
              short_desc: val.short_desc || '',
              meta_title: keywords.title,
              meta_description: keywords.description,
              meta_keywords: keywords.keywords
            }
          };
        }, {});

        setResult(image || '');
        form.setFieldsValue({
          slug,
          ...values
        });
        setFetching(false);
      }
    }
  });

  const [updateBlog, { loading: isUpdating }] = useMutation(UPDATE_BLOG, {
    onCompleted() {
      showSuccess(
        'The blog has been successfully updated. Please note, it may take a little time to become available in the app.'
      );
    },
    onError: err => showErrorMessage(err)
  });

  const onFinish = useCallback(
    (values: BodyType) => {
      setErrorKeys([]);
      const input = {
        image: fileValue,
        ...Object.entries(values).reduce((acc, [key, val]) => {
          if (typeof val === 'string') {
            return {
              ...acc,
              [key]: val
            };
          }

          return {
            ...acc,
            [key]: {
              title: val.title,
              body: val.body,
              short_desc: val.short_desc,
              keywords: {
                title: val.meta_title,
                description: val.meta_description,
                keywords: val.meta_keywords
              }
            }
          };
        }, {})
      };

      if (blogId) {
        return updateBlog({
          variables: {
            id: blogId,
            input
          }
        });
      }

      createBlog({
        variables: {
          input
        }
      });
    },
    [blogId, createBlog, fileValue, updateBlog]
  );

  const onFinishError = (errorInfo: ValidateErrorEntity<BodyType>) => {
    setErrorKeys([]);
    const errors = new Set(
      errorInfo.errorFields.map(item => `${item.name[0]}`)
    );

    setErrorKeys(Array.from(errors));
  };

  return (
    <PageWrapper
      title={blogId ? 'Update' : 'Create'}
      extra={[
        <Button
          type="primary"
          key="save-blog"
          loading={isCreating || isUpdating}
          onClick={form.submit}
        >
          {t('common.save')}
        </Button>
      ]}
    >
      {isFetching ? (
        <Loading />
      ) : (
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishError}
          layout="vertical"
          size="large"
        >
          <Row justify="center" style={{ marginBottom: '--margin-md' }}>
            <Upload
              multiple={false}
              maxCount={1}
              showUploadList={false}
              customRequest={e => upload(e.file as File)}
            >
              {isUploading ? (
                <Spin />
              ) : (
                <Image
                  src={file}
                  width={300}
                  height={200}
                  preview={false}
                  style={{ objectFit: 'cover' }}
                />
              )}
            </Upload>
          </Row>
          <Form.Item
            name="slug"
            label={t('common.slug')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Collapse
            accordion
            defaultActiveKey={LANGUAGES[0].locale}
            items={LANGUAGES.map(item => {
              const hasError = errorKeys.includes(item.locale);

              return {
                key: item.key,
                label: item.label,
                forceRender: true,
                children: (
                  <Panel
                    header={
                      <Text
                        style={{
                          color: `var(--${
                            hasError ? 'error-color' : 'text-color'
                          })`
                        }}
                      >
                        {item.label}
                      </Text>
                    }
                    key={item.locale}
                    forceRender
                  >
                    <Form.Item
                      name={[item.locale, 'meta_title']}
                      label={t('blog.meta_title')}
                      rules={[{ required: true }]}
                    >
                      <Input dir={getInputDir(item.locale)} />
                    </Form.Item>
                    <Form.Item
                      name={[item.locale, 'meta_description']}
                      label={t('blog.meta_description')}
                      rules={[{ required: true }]}
                    >
                      <Input dir={getInputDir(item.locale)} />
                    </Form.Item>
                    <Form.Item
                      name={[item.locale, 'meta_keywords']}
                      label={t('blog.meta_keywords')}
                      rules={[{ required: true }]}
                    >
                      <Input dir={getInputDir(item.locale)} />
                    </Form.Item>
                    <Form.Item
                      name={[item.locale, 'title']}
                      label={t('blog.title')}
                      rules={[{ required: true }]}
                    >
                      <Input dir={getInputDir(item.locale)} />
                    </Form.Item>
                    <Form.Item
                      name={[item.locale, 'short_desc']}
                      label={t('blog.short_desc')}
                      rules={[{ required: true }]}
                    >
                      <Input dir={getInputDir(item.locale)} />
                    </Form.Item>
                    <Form.Item
                      name={[item.locale, 'body']}
                      label="Body"
                      rules={[{ required: true }]}
                    >
                      <CKEditor language={item.locale} />
                    </Form.Item>
                  </Panel>
                )
              };
            })}
          />
        </Form>
      )}
    </PageWrapper>
  );
};

export default BlogNewsDetails;
