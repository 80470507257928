import { FC, useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Card, Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { ChartContainer } from './styled';

const { Paragraph } = Typography;

interface IProps {
  id: string;
  data: {
    category: string;
    consultations: number;
    servicesUsed: number;
  }[];
}

const ConversionsChart: FC<IProps> = ({ data, id }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const chartData = data?.length
      ? data
      : [
          { category: null, consultations: 0, servicesUsed: 0 },
          { category: null, consultations: 0, servicesUsed: 0 }
        ];

    const root = am5.Root.new(id);

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        layout: root.verticalLayout
      })
    );

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'category',
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    );

    xAxis.data.setAll(chartData);

    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: t('analytics.conversions'),
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'consultations',
        categoryXField: 'category',
        tooltip: am5.Tooltip.new(root, {
          labelText: `{categoryX}: {valueY}`
        })
      })
    );

    series.strokes.template.setAll({
      strokeWidth: 2
    });

    series.data.setAll(chartData);

    const secondSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: t('analytics.servicesUsed'),
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'servicesUsed',
        categoryXField: 'category',
        tooltip: am5.Tooltip.new(root, {
          labelText: `{categoryX}: {valueY}`
        })
      })
    );

    secondSeries.strokes.template.setAll({
      strokeWidth: 2,
      strokeDasharray: [4, 4]
    });

    secondSeries.data.setAll(chartData);

    chart.appear(1000, 100);

    return () => root.dispose();
  }, [data, id, t]);

  return (
    <Card
      title={
        <Flex justify="space-between">
          <Paragraph style={{ fontSize: 20, fontWeight: 500, margin: 0 }}>
            {t('partners.conversions')}
          </Paragraph>
        </Flex>
      }
    >
      <ChartContainer>
        <div id={id} className="chartdiv" />
        <div className="remove_logo" />
      </ChartContainer>
    </Card>
  );
};

export default ConversionsChart;
