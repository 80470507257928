import { Button, Flex, QRCode, Typography } from 'antd';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';

import { StyledQrView } from '../_styled';

const { Text } = Typography;

interface IProps {
  color: string;
  onQrCodeChange: (base64: string) => void;
}

function triggerDownload(url: string, fileName: string) {
  const anchor = document.createElement('a');
  anchor.download = fileName;
  anchor.href = url;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}

const QrView: FC<IProps> = ({ color, onQrCodeChange }) => {
  const { t } = useTranslation();
  const qrCodeRef = useRef<HTMLDivElement>(null);

  const encodeQrCodeToBase64 = () => {
    const qrCodeSvg = qrCodeRef.current?.querySelector<SVGElement>('svg');

    if (!qrCodeSvg) {
      return;
    }

    const serializedSvg = new XMLSerializer().serializeToString(qrCodeSvg);
    const svgBlob = new Blob([serializedSvg], {
      type: 'image/svg+xml;charset=utf-8'
    });

    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader.result) {
        onQrCodeChange(reader.result.toString());
      }
    };

    reader.readAsDataURL(svgBlob);
  };

  useEffect(() => {
    encodeQrCodeToBase64();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  const handleDownloadSvgQRCode = () => {
    const qrCodeSvg = qrCodeRef.current?.querySelector<SVGElement>('svg');

    if (!qrCodeSvg) {
      return;
    }

    const serializedSvg = new XMLSerializer().serializeToString(qrCodeSvg);
    const svgBlob = new Blob([serializedSvg], {
      type: 'image/svg+xml;charset=utf-8'
    });

    const svgUrl = URL.createObjectURL(svgBlob);

    triggerDownload(svgUrl, 'QRCode.svg');
  };

  return (
    <StyledQrView>
      <Flex vertical gap={40} align="center">
        <Text style={{ fontSize: 20, fontWeight: 700 }}>
          {t('partners.qr_view')}
        </Text>
        <div ref={qrCodeRef}>
          <QRCode size={210} color={color} type="svg" value="_" />
        </div>
        <Button
          icon={<DownloadOutlined />}
          style={{ width: 210 }}
          onClick={handleDownloadSvgQRCode}
        >
          {t('partners.download_qr')}
        </Button>
      </Flex>
    </StyledQrView>
  );
};

export default QrView;
