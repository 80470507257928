import { Card, Col, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ANALYTICS_BY_PARTNER_ID } from 'gql/partners/queries';

import { PageWrapper } from 'components/ui';
import { ConversationChart, CountriesChart, VisitorsChart } from './components';

import { GetDataByPartnerIdQuery } from '@/generated/graphql';

// const fakeData = [
//   { category: 'Category 1', consultations: 120, servicesUsed: 80 },
//   { category: 'Category 2', consultations: 150, servicesUsed: 90 },
//   { category: 'Category 3', consultations: 200, servicesUsed: 130 },
//   { category: 'Category 4', consultations: 170, servicesUsed: 110 }
// ];

const PartnerAnalytic = () => {
  const { t } = useTranslation();
  const params = useParams();
  const partnerId = params?.id;

  const { data, loading } = useQuery<GetDataByPartnerIdQuery>(
    GET_ANALYTICS_BY_PARTNER_ID,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !partnerId,
      variables: {
        id: partnerId
      }
    }
  );

  const partnerById = data?.getDataByPartnerId?.data.chart_data;

  const countryData: { country: string; value: number }[] = Object.entries(
    partnerById?.country_data || {}
  ).map(([key, value]) => ({
    country: key,
    value: value as number
  }));

  if (loading) {
    return <Spin />;
  }

  return (
    <Card bodyStyle={{ padding: 4 }} style={{ margin: '32px 24px' }}>
      <PageWrapper color="white" back title={t('partners.analytics')}>
        <Row gutter={[30, 30]}>
          <Col span={14}>
            <Row gutter={[30, 30]}>
              <Col span={24}>
                <VisitorsChart
                  id="first-chart"
                  data={partnerById?.gender_by_date}
                />
              </Col>
              <Col span={24}>
                <ConversationChart id="first-charttt" data={[]} />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <CountriesChart data={countryData} />
          </Col>
        </Row>
      </PageWrapper>
    </Card>
  );
};

export default PartnerAnalytic;
