import { useTranslation } from 'react-i18next';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { Button, Image, Table } from 'antd';
import {
  DeleteOutlined,
  // DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useUIDispatchContext } from 'providers/UI';
import { GET_PARTNERS } from 'gql/partners/queries';
import { DELETE_PARTNER } from 'gql/partners/mutation';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'constants/dates';
import { ColumnProps } from 'antd/lib/table';

import { OrganizationAlert, Private } from 'components/shared';
import { ActionMenu, PageWrapper } from 'components/ui';
import { showErrorMessage } from 'utils/showErrorMessage';
import { getFileUrl } from 'utils/file';

import { GetPartnersQuery } from '@/generated/graphql';

type StoryItemType = NonNullable<
  GetPartnersQuery['getPartners']
>['data']['results'][number];

const PAGE_LIMIT = 10;

const Partners = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toggleModal } = useUIDispatchContext();
  const [page, setPage] = useState(0);

  const { data, loading, refetch } = useQuery<GetPartnersQuery>(GET_PARTNERS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      query: {
        pagination: {
          page,
          limit: PAGE_LIMIT
        }
      }
    }
  });

  const [deletePartner] = useMutation(DELETE_PARTNER, {
    onCompleted() {
      refetch();
    },
    onError: err => showErrorMessage(err)
  });

  const onDeletePartner = useCallback(
    (id: number) => {
      toggleModal('confirm', true, {
        onConfirm: () =>
          deletePartner({
            variables: {
              id
            }
          })
      });
    },
    [deletePartner, toggleModal]
  );

  const columns: ColumnProps<StoryItemType>[] = useMemo(
    () => [
      {
        title: t('common.id'),
        dataIndex: 'id',
        width: 50,
        key: 'id'
      },
      {
        title: t('table.logo'),
        dataIndex: 'logo',
        key: 'logo',
        width: 100,
        render: (_, { logo }) => (
          <Image
            preview={false}
            src={getFileUrl(logo)}
            height={60}
            width={60}
            style={{ objectFit: 'contain' }}
          />
        )
      },
      {
        title: t('table.name'),
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: t('partners.code'),
        dataIndex: 'code',
        key: 'code'
      },
      {
        title: t('table.created_at'),
        dataIndex: 'created_at',
        key: 'created_at',
        render: value => dayjs(value).format(DATE_FORMAT)
      },
      {
        title: '',
        dataIndex: 'analytic',
        key: 'analytic',
        align: 'right',
        render: (_, { id }) => (
          <Button
            onClick={() => navigate(`/partner/analytic/${id}`)}
            icon={<EyeOutlined />}
            type="primary"
            ghost
          >
            {t('partners.view_analytics')}
          </Button>
        )
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        width: '5%',
        align: 'right',
        render: (id: string) => (
          <ActionMenu
            data={[
              {
                title: t('table.edit'),
                icon: <EditOutlined style={{ fontSize: 16 }} />,
                permission: {
                  route: PERMISSION_ROUTES.partner,
                  action: PERMISSION_ACTIONS.update
                },
                action() {
                  navigate(`/partner/details/${id}`);
                }
              },
              // {
              //   title: t('partners.download'),
              //   icon: <DownloadOutlined style={{ fontSize: 16 }} />,
              //   action() {
              //     navigate(`/partner/details/${id}`);
              //   }
              // },
              {
                title: t('table.delete'),
                icon: <DeleteOutlined style={{ fontSize: 16 }} />,
                permission: {
                  route: PERMISSION_ROUTES.partner,
                  action: PERMISSION_ACTIONS.delete
                },
                menuProps: {
                  style: {
                    color: 'var(--danger-color)'
                  }
                },
                action: () => onDeletePartner(+id)
              }
            ]}
            placement="bottomRight"
          />
        )
      }
    ],
    [navigate, onDeletePartner, t]
  );

  const results = data?.getPartners?.data.results ?? [];
  const total = data?.getPartners?.data.total;

  return (
    <PageWrapper
      title={t('partners.page_title')}
      extra={[
        <Private
          key="add-service-button"
          route={PERMISSION_ROUTES.partner}
          action={PERMISSION_ACTIONS.create}
        >
          <OrganizationAlert>
            <Button type="primary" onClick={() => navigate('/partner/details')}>
              <PlusOutlined />
              {t('partners.add_new')}
            </Button>
          </OrganizationAlert>
        </Private>
      ]}
    >
      <Table
        dataSource={results}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          total: total,
          pageSize: PAGE_LIMIT,
          onChange: p => setPage(p - 1),
          showSizeChanger: false
        }}
      />
    </PageWrapper>
  );
};

export default Partners;
