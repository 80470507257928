import { FC } from 'react';
import { Button, Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { usePermission } from 'hooks';

import { IActionMenuProps } from './types';

const ActionMenu: FC<IActionMenuProps> = ({
  data,
  placement,
  icon,
  disabled
}) => {
  const { checkUserPermission } = usePermission();

  const menu = data.filter(item =>
    checkUserPermission(item.permission?.route || '', item.permission?.action)
  );

  if (!menu.length) {
    return null;
  }

  return (
    <Dropdown
      disabled={disabled}
      menu={{
        items: menu.map(({ menuProps = {}, title, link, icon, action }) => ({
          onClick: link ? undefined : action,
          key: `${title}`,
          icon: icon,
          label: link ? <Link to={link}>{title as string}</Link> : <>{title}</>,
          ...menuProps
        }))
      }}
      placement={placement}
      trigger={['click']}
    >
      <Button type="text">{icon || <MoreOutlined />}</Button>
    </Dropdown>
  );
};

export default ActionMenu;
