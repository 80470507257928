import { gql } from '@apollo/client';

export const CREATE_PARTNER = gql`
  mutation CreatePartner($input: PartnerBodyInput!) {
    createPartner(input: $input) {
      message
    }
  }
`;

export const UPDATE_PARTNER = gql`
  mutation UpdatePartner($id: ID!, $input: PartnerBodyInput!) {
    updatePartner(id: $id, input: $input) {
      message
    }
  }
`;

export const DELETE_PARTNER = gql`
  mutation DeletePartner($id: ID!) {
    deletePartner(id: $id) {
      message
    }
  }
`;
