import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Col,
  Form,
  Row,
  Space,
  Table,
  Input,
  Select,
  DatePicker,
  Button,
  App
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import { useLazyQuery } from '@apollo/client';
import {
  GENDERS,
  LANGUAGES_USER,
  USER_VERIFY,
  VERIFY_USERS
} from 'constants/users';
import { GET_PATIENTS_LIST } from 'gql/users/queries';
import {
  GetPatients_patientsList_data_results as UserType,
  GetPatients
} from 'gql/users/__generated__/GetPatients';
import { FULL_DATE_FORMAT } from 'constants/dates';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  SendOutlined
} from '@ant-design/icons';
import { PERMISSION_ACTIONS, PERMISSION_ROUTES } from 'constants/permissions';
import { usePermission } from 'hooks';
import { useSearchParams } from 'react-router-dom';
import instance from 'services/api';

import { PageWrapper } from 'components/ui';
import { checkPermission } from 'utils/permission';
import { SendEmailModal } from '../components';
import { Private } from 'components/shared';

const PAGE_LIMIT = 10;
const { Search } = Input;
const { RangePicker } = DatePicker;

type ValuesType = {
  search: string;
  created_at: RangeValue<Dayjs> | undefined;
  birthday_date: RangeValue<Dayjs> | undefined;
  confirm: number | undefined;
  gender: number | undefined;
  lang: string | undefined;
};

const defaultValues: ValuesType = {
  search: '',
  created_at: undefined,
  birthday_date: undefined,
  confirm: undefined,
  gender: undefined,
  lang: undefined
};

const IS_PATIENT_CRM_READY = false;

const Patients: FC = () => {
  const prevValues = useRef<ValuesType>(defaultValues);
  const [isSendVisible, setSendVisible] = useState(false);
  const [isDownloading, setDownloading] = useState(false);

  const { message } = App.useApp();

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { checkUserPermission } = usePermission();
  const [getPatientsList, { data, loading }] =
    useLazyQuery<GetPatients>(GET_PATIENTS_LIST);

  const [page, setPage] = useState(0);

  const getFiltersData = useCallback((values: ValuesType) => {
    const start_date = values.created_at?.length
      ? values.created_at?.[0]?.format()
      : undefined;

    const end_date = values.created_at?.length
      ? values.created_at?.[1]?.format()
      : undefined;

    const birthday_start = values.birthday_date?.length
      ? values.birthday_date?.[0]?.format()
      : undefined;

    const birthday_end = values.birthday_date?.length
      ? values.birthday_date?.[1]?.format()
      : undefined;

    return {
      gender: values.gender,
      confirm: values.confirm,
      start_date,
      end_date,
      birthday_start,
      birthday_end,
      search: values.search,
      lang: values.lang
    };
  }, []);

  useEffect(() => {
    const confirm = searchParams.get('confirm');

    if (confirm) {
      searchParams.delete('confirm');
      setSearchParams(searchParams);
      form.setFieldValue('confirm', Number(confirm));
      form.submit();
    }
  }, [form, searchParams, setSearchParams]);

  useEffect(() => {
    getPatientsList({
      variables: {
        query: {
          ...getFiltersData(prevValues.current),
          pagination: {
            page,
            limit: PAGE_LIMIT
          }
        }
      }
    });
  }, [getFiltersData, getPatientsList, page]);

  const columns: ColumnProps<UserType>[] = useMemo(
    () => [
      {
        title: t('id'),
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: t('table.name'),
        dataIndex: 'name',
        key: 'name',
        render: (_, record) => `${record.first_name} ${record.last_name}`
      },
      {
        title: t('table.email'),
        dataIndex: 'email',
        key: 'email',
        render: (value, record) => (
          <Space size={8} align="center">
            {record.confirm &&
            checkPermission(USER_VERIFY.email, record.confirm) ? (
              <CheckCircleOutlined style={{ fontSize: 20, color: 'green' }} />
            ) : (
              <CloseCircleOutlined style={{ fontSize: 20, color: 'red' }} />
            )}
            <a href={`mailto:${value}`}>{value}</a>
          </Space>
        )
      },
      {
        title: t('table.phone'),
        dataIndex: 'phone',
        key: 'phone',
        render: (value, record) => (
          <Space size={8} align="center">
            {record.confirm &&
            checkPermission(USER_VERIFY.phone, record.confirm) ? (
              <CheckCircleOutlined style={{ fontSize: 20, color: 'green' }} />
            ) : (
              <CloseCircleOutlined style={{ fontSize: 20, color: 'red' }} />
            )}
            <a href={`tel:${value}`}>{value}</a>
          </Space>
        )
      },
      {
        title: 'Language',
        dataIndex: 'lang',
        key: 'lang'
      },
      {
        title: 'Created at',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (value: number) => dayjs(value).format(FULL_DATE_FORMAT)
      },
      {
        title: (
          <>
            {selectedRowKeys?.length ? (
              <Button
                type="primary"
                icon={<SendOutlined />}
                onClick={() => setSendVisible(true)}
              >
                Send email
              </Button>
            ) : (
              <div style={{ width: 125, height: 32 }} />
            )}
          </>
        ),
        width: '160px',
        dataIndex: 'id',
        key: 'id',
        render: () => null
      }
    ],
    [selectedRowKeys?.length, t]
  );

  const onFilter = (values: ValuesType) => {
    setPage(0);
    prevValues.current = values;
    getPatientsList({
      variables: {
        query: {
          ...getFiltersData(values),
          pagination: {
            page: 0,
            limit: PAGE_LIMIT
          }
        }
      }
    });
  };

  const onClear = () => {
    form.resetFields();
    form.submit();
  };

  const results = data?.patientsList?.data.results || [];

  const canReadPatientCrm = checkUserPermission(
    PERMISSION_ROUTES.appointments,
    PERMISSION_ACTIONS.appointmentViewPayment
  );

  const downloadExcelFile = useCallback(async () => {
    try {
      setDownloading(true);
      const filters = getFiltersData(prevValues.current);
      const response = await instance.get('excel/export-patients', {
        params: filters,
        responseType: 'blob'
      });

      const blob = response.data;

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'patients.xlsx';

      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      message.error(
        err?.message || err?.response?.data?.message || 'Something went wrong'
      );
    } finally {
      setDownloading(false);
    }
  }, [getFiltersData, message]);

  return (
    <PageWrapper
      title={t('patient.page_title')}
      color="white"
      extra={[
        <Button
          type="primary"
          disabled={!results.length}
          onClick={downloadExcelFile}
          key="patient-excel-list"
          loading={isDownloading}
        >
          <DownloadOutlined />
          Export file
        </Button>
      ]}
    >
      <Private
        route={PERMISSION_ROUTES.crmPatient}
        action={PERMISSION_ACTIONS.read}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFilter}
          initialValues={defaultValues}
        >
          <Row gutter={8} align="bottom">
            <Col sm={7}>
              <Form.Item label="Search" name="search">
                <Search placeholder={t('common.search')} />
              </Form.Item>
            </Col>
            <Col sm={7}>
              <Form.Item label="Birthday" name="birthday_date">
                <RangePicker
                  style={{ width: '100%' }}
                  format="YYYY-MM-DD"
                  allowClear={false}
                  disabledDate={current => current > dayjs().endOf('day')}
                />
              </Form.Item>
            </Col>
            <Col sm={10}>
              <Form.Item label="Created At" name="created_at">
                <RangePicker
                  style={{ width: '100%' }}
                  format="YYYY-MM-DD"
                  allowClear={false}
                  disabledDate={current => current > dayjs().endOf('day')}
                />
              </Form.Item>
            </Col>
            <Col sm={7}>
              <Form.Item label="Gender" name="gender">
                <Select options={GENDERS} placeholder="Genders" allowClear />
              </Form.Item>
            </Col>
            <Col sm={7}>
              <Form.Item label="User Verify" name="confirm">
                <Select
                  options={VERIFY_USERS}
                  placeholder="User Verify"
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col sm={7}>
              <div style={{ display: 'flex', alignItems: 'flex-end', gap: 8 }}>
                <Form.Item style={{ flex: 1 }} label="Language" name="lang">
                  <Select
                    options={LANGUAGES_USER}
                    placeholder="Language"
                    allowClear
                  />
                </Form.Item>
              </div>
            </Col>
            <Col sm={3}>
              <Form.Item>
                <Space size={8}>
                  <Button type="primary" onClick={() => form.submit()}>
                    Apply
                  </Button>
                  <Button onClick={onClear}>Reset</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Private>
      <Table
        dataSource={results}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          total: data?.patientsList?.data.total || 0,
          onChange: p => setPage(p - 1),
          showSizeChanger: false
        }}
        rowSelection={
          IS_PATIENT_CRM_READY && canReadPatientCrm
            ? {
                onSelect(row) {
                  const { id } = row;

                  setSelectedRowKeys(prev => {
                    const includes = prev.includes(id);

                    if (includes) {
                      return prev.filter(item => item !== id);
                    }

                    return [...prev, id];
                  });
                },
                onSelectAll(selected, rows) {
                  setSelectedRowKeys(prev => {
                    if (selected) {
                      return Array.from(
                        new Set([
                          ...prev,
                          ...rows.filter(item => item).map(item => item.id)
                        ])
                      );
                    }

                    return prev.filter(id => rows.some(item => item.id !== id));
                  });
                },
                selectedRowKeys
              }
            : undefined
        }
      />
      {isSendVisible && (
        <SendEmailModal
          patientIds={selectedRowKeys}
          onClose={() => setSendVisible(false)}
        />
      )}
    </PageWrapper>
  );
};

export default Patients;
