import styled from 'styled-components';

export const GenderCircle = styled.div<{ $gender?: 'male' }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ $gender }) =>
    $gender && $gender === 'male' ? '#1442DB' : '#35B678'};
`;

export const ChartContainer = styled.div`
  position: relative;
  height: 160px;

  .remove_logo {
    position: absolute;
    width: 14%;
    height: 23px;
    background: white;
    bottom: 0;
  }

  > div {
    height: 100%;
  }
`;
